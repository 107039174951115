<template>
  <div>
    <div style="margin: 20px 0 0px 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="test-info">
      <div class="layout-content" >
        <div v-if="!loading">
          <Row>
            <i-col span="2">
              <!--            <img v-if="siteDetail.logo" :src="siteDetail.logo">-->
              <div class="head-img">
                <p>{{dataDeatil.title && dataDeatil.title[0].toUpperCase()}}</p>
                <!--            <img v-if="taskDeatil.project.category" :src="constant.STATIC_URL + taskDeatil.project.category.thumbnail" style="width: 70%;"/>-->
              </div>
            </i-col>
            <i-col span="22">
              <Row>
                <i-col span="24" class="project-detail-info">
                  <div class="info-item" >
                    <strong style="font-size:16px; max-width: 600px; display: inline-block;padding-left: 10px;">
                      <Richtxt :dataObj="{val:dataDeatil.title}" :isInline="true" :isDisabled="true"></Richtxt>
                    </strong>
                  </div>
                </i-col>
              </Row>
              <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
                <i-col span="6">题型 :  {{typesObj[dataDeatil.type]}}</i-col>
                <i-col span="6">难度 :  {{levelsObj[dataDeatil.level] }}</i-col>
                <i-col span="6">创建时间:{{dataDeatil.created_at}}</i-col>
                <i-col span="6">更新时间:{{dataDeatil.updated_at}}</i-col>
              </Row>
              <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
                <i-col span="6">分类 :
                  <span >{{dataDeatil.infoCategoryList}}, </span>
                </i-col>
                <i-col span="6">知识点 :
                  <span v-for="(item,index) in dataDeatil.nodes" :key="index">{{item.node.name}}, </span>
                </i-col>
                <i-col span="6">
                  答对数: {{dataDeatil.right_count }}
                  答题数:{{dataDeatil.answer_count }}
                  答对率:{{dataDeatil.answer_count > 0 ? Math.round(dataDeatil.right_count/dataDeatil.answer_count*100) : '-' }}%
                </i-col>
              </Row>
              <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
                <i-col span="24">关键词 :
                  <span v-for="(item,index) in dataDeatil.labelKeywordsObj" :key="index">{{item.label.name}}, </span>
                </i-col>


              </Row>
              <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
                <i-col span="24">考点 :
                  <span v-for="(item,index) in dataDeatil.labelKeypointsObj" :key="index">{{item.label.name}}, </span>
                </i-col>


              </Row>
              <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
                <i-col span="24">母题标题 :
                  <span>{{dataDeatil.original_name}} </span>
                </i-col>


              </Row>
            </i-col>
          </Row>
        </div>
        <Spin fix v-else></Spin>
      </div>
      <div class="cont">
        <div class="cont-top">
          <div class="tab">
            <div :class="curTab.id == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{ item.name }}</div>
          </div>
        </div>
        <div class="cont-item">
          <component :is="curTab.componentName" :typesObj="typesObj" :dataDeatil="dataDeatil" :allCategoryList="allCategoryList" :statusObj="statusObj" :levelObj="levelsObj" :sourceFroms="sourceFroms" @edit="editSuccess"></component>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import util from '@/utils/tools.js';
import Cont from './components/data/cont'
import Richtxt from '@/components/production/richtxt.vue';

export default {
  name: "test-info",
  data(){
    return{
      dataDeatil:{},
      dataId:'',
      loading:false,
      typesObj:{},
      levelsObj:{},
      tabList:[
        {
          name:'试题内容',
          id:1,
          componentName:'Cont',
          tabName:'cont'
        }
      ],
      curTab:{
        name:'试题内容',
        id:1,
        componentName:'Cont',
        tabName:'cont'
      },
      allCategoryList:[],
      sourceFroms:{},
      statusObj:{},
    }
  },
  components:{
    Cont,
    Richtxt
  },
  created(){
    this.dataId = this.$route.params.id;
    this.getDetail()
  },
  methods:{
    getDetail(){
      let params = {
        id:this.dataId
      };
      this.loading = true;
      this.api.dataset.dataDetail(params).then((res)=>{
        this.loading = false;
        this.dataDeatil = res.info;
        this.typesObj = res.types;
        this.levelsObj = res.levels;
        this.sourceFroms = res.source_froms;
        this.statusObj = res.statuses;
        this.dataDeatil.labelKeywordsObj = this.dataDeatil.labelKeywords;
        this.dataDeatil.labelKeywords = this.dataDeatil.labelKeywords.length ? this.dataDeatil.labelKeywords.map((item)=>{
          return item.label_id
        }) : [];
        this.dataDeatil.labelKeypointsObj = this.dataDeatil.labelKeypoints;
        this.dataDeatil.labelKeypoints = this.dataDeatil.labelKeypoints ? this.dataDeatil.labelKeypoints.map((item)=>{
          return item.label_id
        }) : [];

        this.$set(this.dataDeatil,'categoryIds',[]);
        for(let name in this.dataDeatil.categoryList){
          this.dataDeatil.categoryIds.push(name)
        }

        this.$set(this.dataDeatil,'infoCategoryList',[]);
        for(let name in this.dataDeatil.categoryList){
          let categoryItem = this.dataDeatil.categoryList[name].map((item)=>{
            return item.name;
          }).join('-');
          this.dataDeatil.infoCategoryList.push(categoryItem)
        }
        this.dataDeatil.infoCategoryList = this.dataDeatil.infoCategoryList.join(',');


        this.allCategoryList = [];
        for(let name in res.info.categoryList){
          let names = res.info.categoryList[name].map(sItem=>{
            return sItem.name+'(id:'+sItem.id+')'
          }).join('-');
          this.allCategoryList.push({
            name:names,
            value:name
          })
        }
        // this.examDeatil.batch.name = util.decrypt(this.examDeatil.batch.name);

        this.dataDeatil.created_at = util.timeFormatter(
            new Date(+res.info.created_at * 1000),
            'yyyy-MM-dd'
        );
        this.dataDeatil.updated_at = util.timeFormatter(
            new Date(+res.info.updated_at * 1000),
            'yyyy-MM-dd'
        );


      }).catch((err)=>{
        this.loading = false;
      })
    },
    changeTab(data){
      this.curTab = data;
      this.$router.push({
        name: 'data-detail',
        params: {
          id: this.$route.params.id,
          tab: data.tabName,
        }
      });
    },
    editSuccess(){
      this.getDetail();
    }
  }
}
</script>

<style scoped lang="scss">
.test-info{
  .layout-content{
    margin: 20px;
    padding: 20px;
    background: #ffffff;
    min-height: 100px;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    position: relative;

    .project-detail-info {
      display: flex;
      justify-content: space-between;
    }
    .head-img{
      width: 80px;
      height: 80px;
      border-radius: 80px;
      line-height: 80px;
      background-color: #ccc;
      text-align: center;
      font-size: 55px;
      color:#FFFFFF;
      >img{
        width: 100%;
        height: 100%;
        border-radius: 80px;
      }
    }
    .project-detail-info .info-item button {
      margin-bottom: 5px;
    }
    .project-detail-info .info-item button + button {
      margin-left: 5px;
    }
    .info-item{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .tit-tag{
        padding: 5px 10px;
        text-align: center;
        color:#FFFFFF;
        border-radius: 4px;
        cursor: pointer;
        background-color:#2d8cf0 ;
      }
    }
    .progress{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >span{
        width: 60px;
      }
    }
  }
  .cont{
    margin: 0 20px 20px 20px;
    padding: 20px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    .cont-top{
      display: flex;

    }
    .tab{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border:1px solid #dcdee2;
      text-align: center;
      height: 46px;
      border-radius: 4px;
      overflow: hidden;
      font-size: 14px;
      cursor: default;
      line-height: 46px;

      >div{
        width: 100px;
        border-right:1px solid #dcdee2;
        cursor: pointer;
        height: 100%;
      }
      >div:last-child{
        border-right:none;
      }
      >div.active{
        background-color: #2d8cf0;
        color:#FFFFFF;
      }
    }
  }
}
</style>
