<template>
<div class="data-cont">
  <p style="margin-bottom: 20px"><Button type="primary" @click="correction">{{ $t('trainingcamp_train_cont_edit') }}</Button></p>
  <div class="tit">
    <Richtxt :dataObj="{val:dataDeatil.name}" :isInline="true" :isDisabled="true"></Richtxt>
    <!--    {{dataDeatil.name}}-->
  </div>
  <div class="options" v-if="dataDeatil.options && dataDeatil.options.length">
    <div v-for="(item, i) in dataDeatil.options" :key="item.id">

      <div v-if="dataDeatil.type == 4">
        <Input class="cont-input" disabled size="large" v-model="item.name" ></Input>
      </div>
      <Radio v-else :value="item.is_correct == 1" disabled> {{String.fromCharCode(i+65)}}
        <span class="options-item">
          <Richtxt :dataObj="{val:item.name}" :isInline="true" :isDisabled="true"></Richtxt>
        </span>
      </Radio>
    </div>
  </div>
  <div class="correct" v-if="dataDeatil.type == 1 || dataDeatil.type == 2 || dataDeatil.type == 3">
    {{$t('dataset_data_right_key')}}：<span>{{getRightAnswer()}}</span>
  </div>
  <div class="analysis mt20" style="display: inline-block;">
    <Richtxt :dataObj="{val:dataDeatil.explain}" :isInline="true" :isDisabled="true"></Richtxt>

    <!--    {{dataDeatil.analysis}}-->
  </div>
  <!-- <Modal v-model="correctionModal" title="编辑" width="70%">
    <div v-if="dataDeatil.id && correctionModal">
      <EditData :allData="{data:dataDeatil,allCategory:allCategoryList}" :statusObj="statusObj" :levelObj="levelObj" :typesList="typesList" :sourceFroms="sourceFroms" @changeData="changeData"></EditData>
    </div> -->
<!--    <div class="correction">-->
<!--      <div class="type">-->
<!--        <strong>{{ $t('trainingcamp_train_correction_type') }}:</strong>-->
<!--        <RadioGroup v-model="questionType" size="large" style="margin-left: 20px">-->
<!--          <Radio v-for="item in typesList" :label="item.name" :key="item.value"></Radio>-->
<!--        </RadioGroup>-->
<!--      </div>-->
<!--      <div class="tit">-->
<!--        <div class="left">-->
<!--          <p class="data-tit">{{ $t('trainingcamp_train_correction_title') }}</p>-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <Richtxt v-if="correctionModal" :height="150" :dataObj="{val:correctionInfo.name}" @saveData="(data)=>saveCurData(data,correctionInfo,'name')" key="feedback"></Richtxt>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="option-item" v-for="(item,index) in correctionInfo.options" :key="item.id">-->
<!--        <div class="left">-->
<!--          <p class="data-tit">{{optionNameList[index]}}</p>-->
<!--          <p class="correct" :class="item.is_correct == '1' ? 'active' : ''" @click="changeCorrect(item)">{{item.is_correct == '1' ? $t('trainingcamp_train_correction_correct') : $t('trainingcamp_train_correction_set_correct')}}</p>-->
<!--          <p class="dele" v-if="correctionInfo.options.length > 1" @click="deleOption(index)">{{ $t('trainingcamp_train_correction_delete') }}</p>-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <Richtxt :dataObj="{val:item.name}" :height="150" @saveData="(data)=>saveCurData(data,item,'name')"></Richtxt>-->
<!--        </div>-->
<!--      </div>-->
<!--      <Button type="primary" @click="addOption">{{ $t('trainingcamp_train_correction_add_option') }}</Button>-->
<!--      <div class="analysis">-->
<!--        <div class="left">-->
<!--          <p class="data-tit">{{ $t('trainingcamp_train_correction_analysis') }}</p>-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <Richtxt v-if="correctionModal" @saveData="(data)=>saveCurData(data,correctionInfo,'explain')" :height="150" :dataObj="{val:correctionInfo.explain}" ></Richtxt>-->
       <!-- </div>-->
<!--      </div>-->
<!--    </div> -->
    <!-- <div slot="footer">
      <Button @click="correctionModal=false">{{ $t('trainingcamp_train_feedback_cancel') }}</Button>
      <Button type="primary" @click="confirmCorrection">{{ $t('trainingcamp_train_feedback_confirm') }}</Button>
    </div>
  </Modal> -->

  <el-drawer :visible.sync='correctionModal' :size="1200">
    <div class='drawer-title' slot='title'>
      <div class='drawer-title-text'>编辑</div>
      <div class='drawer-title-btns'>
        <Button @click="correctionModal=false">{{ $t('trainingcamp_train_feedback_cancel') }}</Button>
        <Button type="primary" @click="confirmCorrection" style="margin-left: 10px;">{{ $t('trainingcamp_train_feedback_confirm') }}</Button>
      </div>
    </div>
    <div style='padding:20px;'>
      <div v-if="dataDeatil.id && correctionModal">
        <EditData :allData="{data:dataDeatil,allCategory:allCategoryList}" :statusObj="statusObj" :levelObj="levelObj" :typesList="typesList" :sourceFroms="sourceFroms" @changeData="changeData"></EditData>
      </div>
    </div>
  </el-drawer>

</div>
</template>

<script>
import Richtxt from '@/components/production/richtxt.vue';
import EditData from './editData'
export default {
  name: "contView",
  data(){
    return {
      correctionModal:false,
      optionNameList:[this.$t('trainingcamp_train_correction_option_a'),this.$t('trainingcamp_train_correction_option_b'),this.$t('trainingcamp_train_correction_option_c'),this.$t('trainingcamp_train_correction_option_d'),this.$t('trainingcamp_train_correction_option_e'),this.$t('trainingcamp_train_correction_option_f'),this.$t('trainingcamp_train_correction_option_g')],
      correctionInfo:{},
      questionType:'',
      typesList:[],
    }
  },
  components:{
    Richtxt,
    EditData
  },
  props:{
    dataDeatil:{
      type:Object,
      default:()=>{}
    },
    typesObj:{
      type:Object,
      default:()=>{}
    },
    sourceFroms:{
      type:Object,
      default:()=>{}
    },
    statusObj:{
      type:Object,
      default:()=>{}
    },
    levelObj:{
      type:Object,
      default:()=>{}
    },
    allCategoryList:{
      type:Array,
      default:()=>[]
    }
  },
  watch:{
    typesObj:{
      handler(newV){
        this.typesList = [];
        for(let name in newV){
          this.typesList.push({
            name:newV[name],
            value:name
          })
        }
      },
      immediate:true
    },
  },
  methods:{
    getRightAnswer(){
      var rightanswer = '';
      if(this.dataDeatil.options){
        this.dataDeatil.options.forEach((item, i)=>{
          if (item.is_correct == '1'){
            var codestr = (i + 65);
            rightanswer +=  String.fromCharCode(codestr); //str.charCodeAt()
          }
        });
      }

      return rightanswer;
    },
    correction(){
      this.correctionModal = true;
      this.correctionInfo = JSON.parse(JSON.stringify(this.dataDeatil));
      this.questionType = this.typesObj[this.correctionInfo.type];
    },
    // saveCurData(curData,data,name){
    //   data[name] = curData;
    // },
    // changeCorrect(data){  //修改正确选项
    //   if(this.correctionInfo.type == '1' || this.correctionInfo.type == '3'){ //单选
    //     if(data.is_correct == '0'){
    //       this.correctionInfo.options.forEach((item)=>{
    //         item.is_correct = '0';
    //       });
    //       this.$nextTick(()=>{
    //         data.is_correct = '1';
    //       })
    //
    //       console.log(data)
    //     }else{
    //       this.$nextTick(()=>{
    //         data.is_correct = '0';
    //       })
    //     }
    //   }else{
    //     data.is_correct == '0'  ? data.is_correct = '1' : data.is_correct = '0';
    //   }
    //
    // },
    // addOption(){  //添加选项
    //   if(this.correctionInfo.options.length == 6){
    //     this.$Message.warning(this.$t('trainingcamp_train_option_max'));
    //     return;
    //   }
    //   this.correctionInfo.options.push({
    //     name:'',
    //     id:'0',
    //     is_correct:'0'
    //   })
    // },
    // deleOption(index){  //删除选项
    //   this.correctionInfo.options.splice(index, 1);
    // },
    changeData(data){
      this.correctionInfo = JSON.parse(JSON.stringify(data));
    },
    confirmCorrection(){  //确认纠错
      if(!this.correctionInfo.categoryIds.length){
        this.$Message.warning('请选择分类');
        return;
      }
      if(!this.correctionInfo.name){  //标题
        this.$Message.warning(this.$t('trainingcamp_train_warning_tit'));
        return;
      }
      if(this.correctionInfo.type == '1' || this.correctionInfo.type == '2' || this.correctionInfo.type == '3' || this.correctionInfo.type == '4') {
        if (!this.correctionInfo.options.length) { //选项
          this.$Message.warning(this.$t('trainingcamp_train_warning_add_option'));
          return;
        }
        for(let i=0;i<this.correctionInfo.options.length;i++){
          if(!this.correctionInfo.options[i].name){
            this.$Message.warning(this.$t('trainingcamp_train_warning_input_cont'));
            return;
            break;
          }
        }
      }else{
        this.correctionInfo.options = [];
      }
      if(this.correctionInfo.type == '1' || this.correctionInfo.type == '2' || this.correctionInfo.type == '3') {

        let len = this.correctionInfo.options.filter((item) => {
          return item.is_correct == '1';
        }).length
        if (!len) { //正确选项
          this.$Message.warning(this.$t('trainingcamp_train_warning_select'));
          return;
        }
        if(this.correctionInfo.type == '1' || this.correctionInfo.type == '3'){
          if(len > 1){
            this.$Message.warning('只能选择一个正确答案');
            return;
          }
        }
      }




      if(!this.correctionInfo.labelKeywords.length){
        this.$Message.warning('请选择标签');
        return;
      }
      // if(!this.correctionInfo.datasetData.explain){ //解析
      //   this.$Message.warning('请输入解析');
      //   return;
      // }


      let params = {
        id:this.correctionInfo.id,
        name:this.correctionInfo.name,
        explain:this.correctionInfo.explain,
        options:JSON.stringify(this.correctionInfo.options),
        type:this.correctionInfo.type,
        level:this.correctionInfo.level,
        status:this.correctionInfo.status,
        source:this.correctionInfo.source,
        label_keyword_ids:this.correctionInfo.labelKeywords.join(','),
        label_keypoint_ids:this.correctionInfo.labelKeypoints.join(','),
        category_ids:this.correctionInfo.categoryIds.join('|'),
        site_id:'-1'
      };

      this.api.dataset.dataUpdate(params).then((res)=>{
        this.correctionModal = false;
        this.$Message.success(this.$t('trainingcamp_train_cont_edit_success'));
        // this.getInfo()
        this.$emit('edit')
      })
    }
  }
}
</script>

<style scoped lang="scss">
.data-cont{
  margin-top: 20px;
  font-size: 14px;

  .tit{
    font-size: 16px;
    display: inline-block;
  }
  .options{
    margin-top: 10px;
    margin-left: 10px;
    >div{
      line-height: 40px;
    }
    .options-item{
      display: inline-block;
    }
  }
  .correct{
    margin-top: 10px;
    margin-bottom: 10px;
    >span{
      color: #2d8cf0;
    }
  }
}
.correction{  //纠错
  height: 500px;
  overflow-y: auto;
  .type{
    font-size: 20px;
    margin-bottom: 20px;
  }
  .tit,
  .option-item,
  .analysis{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    .left{
      width: 100px;
      text-align: right;
      font-size: 14px;
      overflow: hidden;


      .correct{
        margin: 5px 0;
        border: 1px solid #dcdee2;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        cursor: pointer;
      }
      .dele{
        float: right;
        width: 50px;
        color:#FFFFFF;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        background-color: #ed4014;
        cursor: pointer;
      }
      .active{
        border: 1px solid #19be6b;
        background-color: #19be6b;
        color:#FFFFFF;
      }
    }
    .right{
      margin-left: 20px;
      flex: 1;
      border: 1px solid #dcdee2;
    }
  }
  .analysis{
    margin-top: 20px;
  }
}
</style>
